@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: DIN;
  src: url(./assets/fonts/DIN-Medium.otf) format('opentype');
  font-weight: regular;
}

@font-face {
  font-family: DIN;
  src: url(./assets/fonts/DIN-Regular.otf) format('opentype');
  font-weight: lighter;
}

@font-face {
  font-family: DIN;
  src: url(./assets/fonts/DIN-Bold.otf) format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: D-DIN;
  font-weight: normal;
  src: url(./assets/fonts/D-DIN.otf) format('opentype');
}

@font-face {
  font-family: 'D-DIN Exp';
  src: url(./assets/fonts/D-DINExp.otf) format('opentype');
}

@font-face {
  font-family: 'D-DIN Exp';
  font-weight: bold;
  src: url(./assets/fonts/D-DINExp-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'D-DIN Condensed';
  src: url(./assets/fonts/D-DINCondensed.otf) format('opentype');
}
